import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetchCustomLoopZonalPricing(ctx, payload) {
      return await Vue.axios.get(`/dashboard/custom-loop-zonal-pricing`, {
        params: payload
      });
    },
    async fetchZonalPricing() {
      const { data } = await Vue.axios.get("/dashboard/zonal-pricings");
      return data;
    },
    async createCustomLoopZonalPricing(ctx, payload) {
      const { data } = await Vue.axios.post(
        `/dashboard/custom-loop-zonal-pricing`,
        payload
      );
      return data;
    },
    async updateCustomLoopZonalPricing(ctx, payload) {
      const { data } = await Vue.axios.put(
        `/dashboard/custom-loop-zonal-pricing/${payload.id}`,
        payload
      );
      return data;
    },
    async updateZonalPricing(ctx, payload) {
      const { data } = await Vue.axios.put(
        `/dashboard/zonal-pricing/${payload.id}`,
        payload
      );
      return data;
    },
    async fetchCharterPricing() {
      const { data } = await Vue.axios.get("/dashboard/vehicle/pricings");
      return data;
    },
    async updateDayHirePricing(ctx, payload) {
      const { data } = await Vue.axios.put(
        `/dashboard/vehicle/day-hire-pricing/${payload.id}`,
        payload
      );
      return data;
    },
    async fetchDayHirePricing() {
      const { data } = await Vue.axios.get(
        "/dashboard/vehicle/day-hire-pricing"
      );
      return data;
    },

    async updateCharterPricing(ctx, payload) {
      const { data } = await Vue.axios.put(
        `/dashboard/vehicle/pricings/${payload.id}`,
        payload
      );
      return data;
    },
    async deleteCustomLoopZonalPricing(_ctx, { id }) {
      const { message } = await Vue.axios.delete(
        `dashboard/custom-loop-zonal-pricing/${id}`
      );
      return message;
    }
  },

  mutations: {},

  getters: {}
};
